@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 14px;
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    height: 100%;
    @apply text-gray-800;
  }

  *,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
  }

  .turbolinks-progress-bar {
    background: #ff1b4e;
  }
}
@layer components {
  .btn {
    @apply font-medium py-2 px-6 rounded-md cursor-pointer;
  }

  .btn-gradient-red {
    background: #FC3E69;
    background: -moz-linear-gradient(90deg, #ff7e32 0%, #ff2557 100%);
    background: -webkit-linear-gradient(90deg, #ff7e32 0%, #ff2557 100%);
    background: linear-gradient(90deg, #ff7e32 0%, #ff2557 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff7e32",endColorstr="#ff2557",GradientType=1);
    @apply leading-6 text-white transition ease-in-out;
  }
  .btn-gradient-red:hover {
    background: #ff1b4e;
    background: -moz-linear-gradient(90deg, #ff6d14 0%, #ff1b4e 100%);
    background: -webkit-linear-gradient(90deg, #ff6d14 0%, #ff1b4e 100%);
    background: linear-gradient(90deg, #ff6d14 0%, #ff1b4e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff6d14",endColorstr="#ff1b4e",GradientType=1);
  }

  .btn-green {
    @apply bg-green-500 text-white transition ease-in-out;
  }
  .btn-green:hover {
    @apply bg-green-300;
  }

  .btn-blue {
    @apply bg-blue-500 text-white transition ease-in-out;
  }
  .btn-blue:hover {
    @apply bg-blue-300;
  }

  .btn-red {
    @apply bg-red-500 text-white transition ease-in-out;
  }
  .btn-red:hover {
    @apply bg-red-300;
  }

  .btn-outline {
    @apply bg-gray-100 text-gray-500 transition ease-in-out;
  }
  .btn-outline:hover {
    @apply bg-gray-200 text-gray-800;
  }

  form label {
    @apply block font-medium mb-1;
  }
  form input, form select, form textarea {
    @apply outline-none w-full bg-gray-100 text-gray-700 text-lg rounded py-3 px-4 dark:bg-gray-700 dark:text-gray-300;
  }
  form input:focus, form select:focus, form textarea:focus {
    @apply bg-white text-gray-900 ring-2 ring-gray-200 dark:bg-gray-600 dark:ring-gray-900 dark:text-gray-100;
  }
  form input[type=checkbox] {
    @apply h-4 w-4;
  }
  form .errors ul li {
    @apply text-sm text-red-500 font-medium mt-2;
  }

  form#form-admin label,
form.is_toggle label {
    @apply block text-gray-500 mb-1;
  }
  form#form-admin input, form#form-admin select, form#form-admin textarea,
form.is_toggle input,
form.is_toggle select,
form.is_toggle textarea {
    @apply outline-none w-full bg-white text-gray-900 ring-1 ring-gray-200 text-lg rounded py-3 px-4;
  }
  form#form-admin .toggle_dot,
form.is_toggle .toggle_dot {
    top: -0.25rem;
    left: -0.25rem;
    transition: all 0.3s ease-in-out;
  }
  form#form-admin input:checked ~ .toggle_dot,
form.is_toggle input:checked ~ .toggle_dot {
    @apply bg-green-500;
    transform: translateX(100%);
  }
  form#form-admin .sortable-ghost,
form.is_toggle .sortable-ghost {
    @apply outline-none w-full bg-gray-50 border-2 border-dashed border-gray-200 rounded py-3 px-4;
  }

  form#search input {
    background: transparent;
    @apply outline-none appearance-none border-b border-gray-300 font-medium text-gray-200;
  }
  form#search input:focus {
    @apply text-white border-gray-100;
  }

  .alert {
    @apply rounded-lg w-full py-4 px-6 flex items-center font-medium;
  }

  .alert-error {
    @apply bg-red-50 text-red-600;
  }

  .alert-success {
    @apply bg-green-50 text-green-600;
  }

  .alert-infos {
    @apply bg-blue-50 text-blue-600;
  }
}
@layer utilities {
  @responsive {
    .text-shadow {
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    }

    .text-shadow-md {
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.08);
    }

    .text-shadow-lg {
      text-shadow: 0 15px 30px rgba(0, 0, 0, 0.14), 0 5px 15px rgba(0, 0, 0, 0.08);
    }

    .text-shadow-none {
      text-shadow: none;
    }
  }
}