@responsive {
    .text-shadow {
        text-shadow: 0 2px 4px rgba(0,0,0,0.25);
    }

    .text-shadow-md {
        text-shadow: 0 4px 8px rgba(0,0,0,0.20), 0 2px 4px rgba(0,0,0,0.08);
    }

    .text-shadow-lg {
        text-shadow: 0 15px 30px rgba(0,0,0,0.14), 0 5px 15px rgba(0,0,0,0.08);
    }

    .text-shadow-none {
        text-shadow: none;
    }
}
